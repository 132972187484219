import { useEffect, useState } from "react"; 

const backendHost = window.location.hostname === 'localhost' ? 'localhost:1991' : `backend.${window.location.hostname}`;

function Test () {
    const [message, setMessage] = useState("Loading")
    useEffect(() => {
        fetch(`${window.location.protocol}//${backendHost}/say-hello`)
            .then(r => r.json())
            .then(value => setMessage(value.message))
    }, []);
    return <h1>{message}</h1>;
}

export default Test;
